import React from 'react';
import {Link} from "react-router-dom";
import styled from "styled-components";
import logocihe from '../media/logo_cihe.png'
import logofadu2 from '../media/logo_fadu_02.jpg'

export const Header = () => {
    return (
        <HeaderBox>
            <Logo as={Link} to={'/'}><LogoCihe src={logocihe} /></Logo>
            <Logo>
                <a target="_blank" href="http://www.fadu.uba.ar/">
                    <LogoFadu src={logofadu2} />
                </a>
            </Logo>
        </HeaderBox>
    )
}

const HeaderBox = styled.div`{
  width: 100%;
  position: fixed;
  top: 0;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
}`

const Logo = styled.div`{
  margin-top: 20px;
  margin-bottom: 20px;
}`

const LogoCihe = styled.img`{
  width: 250px;
  margin-left: 130px;
  
  @media (max-width: 700px) {
    margin-left: 30px;
  }
  
  @media(max-width: 600px) {
    width: 180px;
  }
  
  @media(max-width: 400px) {
    width: 150px;
  }
}`

const LogoFadu = styled.img`{
  width: 250px;
  margin-right: 30px;
  
  @media(max-width: 600px) {
    width: 180px;
  }
  
  @media(max-width: 400px) {
    width: 150px;
  }
}`
