import React from "react";
import styled from "styled-components";
import SectionTitle from '../components/SectionTitle';
import team from '../data/team.json';
import {Member} from "../components/Member";

export const Equipo = () => {
    const director = team.filter(member => member['cargo'] === 'Director del Centro')[0];
    const founders = team.filter(member => member['cargo'] === 'Fundadores del Centro y Directores hasta 2016');
    const magisters = team.filter(member => member['cargo'] === 'Tesistas de Maestría');
    const phds = team.filter(member => member['cargo'] === 'Tesistas de Doctorado');
    const researchers = team.filter(member => member['cargo'] === 'Investigadores invitados');
    const interns = team.filter(member => member['cargo'] === 'Pasantes de investigación');
    return (
        <>
            <SectionTitle text=""/>
            <Subsection>
                <Cargo>Director del Centro</Cargo>
                <Member
                    titulos={director['titulos']}
                    nombre={director['nombreCompleto']}
                    cargoAdicional={director['cargoAdicional']}
                    extracto={director['extracto']}
                    foto={director['foto']}
                    mail={director['mail']}
                    link={director['link']}
                />
            </Subsection>
            <Subsection>
                <Cargo>Fundadorxs del Centro y Directorxs hasta 2016</Cargo>
                {
                    founders.map((member, index) => {
                        return <Member
                            key={member['nombreCompleto'] + index}
                            titulos={member['titulos']}
                            nombre={member['nombreCompleto']}
                            cargoAdicional={member['cargoAdicional']}
                            extracto={member['extracto']}
                            foto={member['foto']}
                            mail={member['mail']}
                            link={member['link']}
                        />
                    })
                }
            </Subsection>
            <Subsection>
                <Cargo>Docentes– Investigadorxs FADU-UBA</Cargo>
                {
                    magisters.map((member, index) => {
                        return <Member
                            key={member['nombreCompleto'] + index}
                            titulos={member['titulos']}
                            nombre={member['nombreCompleto']}
                            cargoAdicional={member['cargoAdicional']}
                            extracto={member['extracto']}
                            foto={member['foto']}
                            mail={member['mail']}
                            link={member['link']}
                        />
                    })
                }
            </Subsection>
            <Subsection>
                <Cargo>Tesistas de Doctorado</Cargo>
                {
                    phds.map((member, index) => {
                        return <Member
                            key={member['nombreCompleto'] + index}
                            titulos={member['titulos']}
                            nombre={member['nombreCompleto']}
                            cargoAdicional={member['cargoAdicional']}
                            extracto={member['extracto']}
                            foto={member['foto']}
                            mail={member['mail']}
                            link={member['link']}
                        />
                    })
                }
            </Subsection>
            <Subsection>
                <Cargo>Investigadorxs invitadxs</Cargo>
                {
                    researchers.map((member, index) => {
                        return <Member
                            key={member['nombreCompleto'] + index}
                            titulos={member['titulos']}
                            nombre={member['nombreCompleto']}
                            cargoAdicional={member['cargoAdicional']}
                            extracto={member['extracto']}
                            foto={member['foto']}
                            mail={member['mail']}
                            link={member['link']}
                        />
                    })
                }
            </Subsection>
            <Subsection>
                <Cargo>Pasantes de investigación</Cargo>
                {
                    interns.map((member, index) => {
                        return <Member
                            key={member['nombreCompleto'] + index}
                            titulos={member['titulos']}
                            nombre={member['nombreCompleto']}
                            cargoAdicional={member['cargoAdicional']}
                            extracto={member['extracto']}
                            foto={member['foto']}
                            mail={member['mail']}
                            link={member['link']}
                        />
                    })
                }
            </Subsection>
        </>
    );
}

const Cargo = styled.p`{
  font-size: 18px;
  margin: 0 0 15px;
}`

const Subsection = styled.div`{
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}`
