function changeCircleStyle(circles) {
    for (let i=0; i<circles.length; i++){
        circles[i].style.background = "#fff"
        circles[i].style.border = "1px solid #999999"
    }
}

function activePage(page) {
    const circles = document.getElementsByClassName('circle')
    if (circles.length > 0) {
        changeCircleStyle(circles)
        const circle = document.getElementById(page)
        circle.style.background = "#fbb914"
        circle.style.border = "1px solid #fbb914"
    }
}

const sections = ["institucional", "equipo", "investigacion", "transferencia", "docencia", "contacto"]

module.exports = { activePage, sections }