import React, {useState} from 'react';
import mas from "../media/mas.png";
import styled from "styled-components";
import {Extract} from "./Extract";
import menos from "../media/menos.png";

export const Member = (props) => {
    const [showExtract, setShowExtract] = useState(false);
    return (
        <Container>
            <Person onClick={() => setShowExtract(!showExtract)}>
                <Titulo>{props.titulos} {props.nombre}</Titulo>
                <Icon src={showExtract ? menos : mas}/>
            </Person>
            {
                (showExtract) &&
                <Extract
                    extracto={props.extracto}
                    foto={props.foto}
                    mail={props.mail}
                    link={props.link}
                />
            }
        </Container>
    )
}

const Container = styled.div`{
  display: flex;
  flex-direction: column;
}`

const Person = styled.div`{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  margin-top:-10px;
  flex-grow: 1;
}`

const Titulo = styled.p`{
  font-size: 18px;
  font-family: GothamMedium, sans-serif;
}`

const Icon = styled.img`{
  align-self: center;
  font-size: 15px;
  justify-self: flex-end;
  cursor: pointer;
  margin-left: 15px;
}`
