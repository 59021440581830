import React from "react";
import SectionTitle from '../components/SectionTitle';
import projects from '../data/projects.json';
import {ResearchProject} from "../components/ResearchProject";
import styled from "styled-components";

export const Investigacion = () => {
    return (
        <>
            <div>
                <SectionTitle text=""/>
            </div>
            <GrayLine />
            <Projects>
            {
                projects.map((project, index) => {
                    return <ResearchProject
                                key={index}
                                year={project['fechaInicio'] + '-' + project['fechaFin'].slice(-2)}
                                projectName={"'" + project['titulo'] + "'"}
                                projectInstitution={project['financiamiento']}
                                projectDirectors={project['direccion']}
                                projectCoDirectors={project['coDireccion']}
                                projectTutors={project['tutor']}
                                projectExtract={project['resumen']}
                                link={project['link']}
                    />
                })
            }
            </Projects>
        </>
    );
}

const Projects = styled.div``

const GrayLine = styled.div`{
  width: 100%;
  border-bottom: 1px solid #999999;
}`

