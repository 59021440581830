import React, {useState} from "react";
import styled from "styled-components";
import menos from "../media/menos.png";
import mas from "../media/mas.png";

export const ActivityPeriodContainer = (props) => {
    const [showInfo, setShowInfo] = useState(true);

    return (
        <>
            <Container onClick={() => setShowInfo(!showInfo)}>
                <Period>{props.activity['periodo']}</Period>
                <PlusIcon src={showInfo ? menos : mas}/>
            </Container>
            {
                (showInfo) &&
                <List>
                    {
                        props.activity['acciones'].map((act, index) => {
                            return <Row key={index}>
                                <Year>{act['fecha']}</Year>
                                <p>{act['contenido']}</p>
                            </Row>
                        })
                    }
                </List>
            }
        </>

    );
}

const Container = styled.div`{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  cursor: pointer;
}`

const List = styled.div`{
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}`

const Row = styled.div`{
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #999999;
  padding: 10px 0;

  p {
    margin: 0;
    flex-shrink: 1;
    flex-grow: 0;

    @media(max-width: 500px) {
      margin-bottom: 10px;
    }
  }

  @media(max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
}`

const Period = styled.p`{
  font-family: GothamMedium, sans-serif;
  font-size: 22px;
  color: #231f20;
  margin: 0 5px 0 0;
}`

const Year = styled.p`{
  font-family: GothamMedium, sans-serif;
  font-size: 18px;
  color: #231f20;
  min-width: 120px;
  margin-right: 5px;
}`

const PlusIcon = styled.img`{
  font-size: 15px;
  margin-left: 15px;
}`
