import './App.css';
import React, {createContext, useState} from "react";
import {BrowserRouter, Switch} from 'react-router-dom';
import {Route} from 'react-router';
import {Navbar} from "./components/Navbar";
import styled from "styled-components";
import {Investigacion} from "./pages/Investigacion";
import {Institucional} from "./pages/Institucional";
import {Sidebar} from "./components/Sidebar";
import {Header} from "./components/Header";
import {Docencia} from "./pages/Docencia";
import {Equipo} from "./pages/Equipo";
import {Transferencia} from "./pages/Transferencia";
import {Contacto} from "./pages/Contacto";
import {PageNotFound} from "./pages/PageNotFound";

export const SectionsContext = createContext();

export const App = () => {
    const [currentSection, setCurrentSection] = useState('institucional');
    return (
        <BrowserRouter>
            <SectionsContext.Provider value={{ currentSection, setCurrentSection }}>
                <AppContainer>
                    <Header/>
                    <MainBox>
                        <LeftBox>
                            <Sidebar/>
                        </LeftBox>
                        <RightBox>
                            <Switch>
                                <Route path="/" component={Institucional} exact/>
                                <Route path="/institucional" component={Institucional} exact/>
                                <Route path="/equipo" component={Equipo} exact/>
                                <Route path="/investigacion" component={Investigacion} exact/>
                                <Route path="/transferencia" component={Transferencia} exact/>
                                <Route path="/docencia" component={Docencia} exact/>
                                <Route path="/contacto" component={Contacto} exact/>
                                <Route path="*" component={PageNotFound} />
                            </Switch>
                        </RightBox>
                    </MainBox>
                    <Navbar/>
                </AppContainer>
            </SectionsContext.Provider>
        </BrowserRouter>
    );
}

const AppContainer = styled.div`{}`

const MainBox = styled.div`{
  margin-top: 200px;
  margin-bottom: 100px;
  margin-right: 100px;
  display: flex;
  flex-direction: row;

  @media (max-width: 1100px) {
    margin-right: 30px;
  }
}`

const LeftBox = styled.div`{
  width: 25%;
  flex-shrink: 1;

  @media (max-width: 950px) {
    display: none;
  }
}`

const RightBox = styled.div`{
  margin-left: 18px;
  margin-right: 20px;
  width: 75%;
  flex-grow: 1;
}`
