import React, {useContext} from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import i_mail from '../media/i_mail.png'
import i_fb from '../media/facebook.png'
import {SectionsContext} from "../App";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram} from "@fortawesome/free-brands-svg-icons/faInstagram";

const sections = require('../functions/utils')

export const Sidebar = () => {
    const { currentSection, setCurrentSection } = useContext(SectionsContext)
    return (
        <>
            <YellowLine/>
            <SidebarBox>
                {sections.sections.map((section, index) => (
                    <SidebarElement
                        key={index}
                        as={Link}
                        to={'/'.concat(section)}
                        onClick={() => setCurrentSection(section)}
                    >
                        <Circle isSelected={(currentSection === section)} />
                        <Text>{section}</Text>
                    </SidebarElement>
                ))}
                <IconBox>
                    <Icon as={Link} to={'/contacto'}><MailIcon src={i_mail}/></Icon>
                    <Icon>
                        <a href="https://www.facebook.com/CIHE.FADU" target="_blank">
                            <FbIcon src={i_fb} />
                         </a>
                    </Icon>
                    <Icon>
                        <a href="https://www.instagram.com/cihe_fadu_uba/?igshid=NzNkNDdiOGI%3D">
                            <_FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </Icon>
                </IconBox>
            </SidebarBox>
        </>
    );
}

const _FontAwesomeIcon = styled(FontAwesomeIcon)`
    color: #666;
    margin-top: 10px;
`

const YellowLine = styled.div`{
  background: #fbb914;
  height: 5px;
  width: 100%;
  flex-shrink: 1;
  margin-top: 20px;
  margin-bottom: 20px;
}`

const Text = styled.p`{
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}`

const SidebarBox = styled.div`{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
}`

const Circle = styled.div`{
  border-radius: 50%;
  border: ${(props) => (props.isSelected ? '1px solid #fbb914' : '1px solid #999999')};
  background-color: ${(props) => (props.isSelected ? '#fbb914' : '#FFF')};
  height: 10px;
  width: 10px;
  margin-right: 20px;
  flex-shrink: 0;
}`

const SidebarElement = styled.a`{
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  padding-bottom: 12px;
  font-size: 15px;
  font-family: GothamLight, sans-serif;
  color: #666666;

  :hover ${Circle} {
    background: #fbb914;
    border: 1px solid #fbb914;
  }
}`

const IconBox = styled.div`{
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-items: start;
}`

const Icon = styled.div`{
  cursor: pointer;
}`

const MailIcon = styled.img`{
  width: 15px;
  margin-top: 10px;
}`

const FbIcon = styled.img`{
  width: 7px;
  margin-top: 10px;
}`
