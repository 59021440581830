import React, {useState} from 'react';
import styled from "styled-components";
import mas from "../media/mas.png";
import menos from "../media/menos.png";
import i_web from "../media/i_web.png";

export const ResearchProject = (props) => {
    const [showExtract, setShowExtract] = useState(false);

    return (
        <ResearchProjectBox onClick={() => setShowExtract(!showExtract)}>
            <Content>
                <Year>{props.year}</Year>
                <Info>
                    <p>
                        <span style={styles.projectName}>{props.projectName} </span>
                        <span style={styles.projectName}>{props.projectInstitution} </span>
                        <span style={styles.projectDirectors}>Dirección: {props.projectDirectors}. </span>
                        {
                            (props.projectCoDirectors !== "") && <span style={styles.projectDirectors}>Co-dirección: {props.projectCoDirectors}. </span>
                        }
                        {
                            (props.projectTutors !== "") && <span style={styles.projectDirectors}>Tutores: {props.projectTutors}.</span>
                        }
                    </p>
                    {
                        (props.projectExtract !== "") &&
                        <PlusIcon id="iconAccordion" style={styles.icon} src={showExtract ? menos : mas} />
                    }
                </Info>
            </Content>
            <Extract>
                {
                    (showExtract) &&
                    <>
                        <Title>Resumen</Title>
                        <Body>{props.projectExtract}</Body>
                        {props.link && <a href={props.link} target="_blank"><img src={i_web} style={styles.icon} alt="Link externo del investigadore"/></a>}
                    </>
                }
            </Extract>
        </ResearchProjectBox>
    )
}

const styles = {
    projectName: {
        fontFamily: "GothamMedium",
        color: "#231f20",
        fontSize: "18px",
        marginTop: "0px",
    },
    projectDirectors: {
        fontFamily: "GothamLight",
        color: "#231f20",
        fontSize: "18px",
        marginTop: "0px",
    },
}


const ResearchProjectBox = styled.div`{
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #999999;
  width: 100%;
  cursor: pointer;
  padding-bottom: 15px;
}`

const Content = styled.div`{
  display: flex;
  flex-direction: row;
  
  @media(max-width: 500px) {
    flex-direction: column;
  }
}`

const Info = styled.div`{
  display: flex;
  flex-direction: row;
  margin-right: 8px;
}`

const Year = styled.p`{
  font-family: GothamMedium, sans-serif;
  font-size: 18px;
  color: #231f20;
  min-width: 100px;
  margin-right: 5px;

  @media(max-width: 500px) {
    margin-bottom: 0;
  }
}`

const PlusIcon = styled.img`{
  align-self: center;
  font-size: 15px;
  justify-self: flex-end;
  margin-left: 15px;
}`

const Extract = styled.div`{
  font-family: GothamLight, sans-serif;
  color: #231f20;
  font-size: 18px;
}`

const Title = styled.p`{
  font-family: GothamMedium, sans-serif;
  color: #231f20;
  font-size: 18px;
  margin: 0;
}`

const Body = styled.p`{
  margin: 0;
  padding-bottom: 20px;
}`
