import React from "react";
import SectionTitle from '../components/SectionTitle'
import styled from "styled-components";

export const Contacto = () => {
    return (
        <div>
            <SectionTitle text="CONTACTO"/>
            <Box>
                <Content>
                    <Title>Mail</Title>
                    <Info>cihe@fadu.uba.ar</Info>
                </Content>
                {/*<Content>*/}
                {/*    <Title>Teléfono</Title>*/}
                {/*    <Info>(+5411) 5285 9296</Info>*/}
                {/*</Content>*/}
                <Content>
                    <Title>Dirección</Title>
                    <Info>
                        Facultad de Arquitectura, Diseño y Urbanismo, Universidad de Buenos Aires (FADU-UBA)
                        Pabellón 3, piso 4, Ciudad Universitaria (1428) Ciudad Autónoma de Buenos Aires, Argentina
                    </Info>
                </Content>
            </Box>
        </div>
    );
}

const Box = styled.div`{
  display: flex;
  flex-direction: column;
}`;

const Content = styled.div`{
  display: flex;
  flex-direction: column;
}`;

const Title = styled.p`
  font-family: GothamMedium, sans-serif;
  font-size: 22px;
  color: #231f20;
  margin-bottom: 0;
`;

const Info = styled.span``;
