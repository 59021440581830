import React, {useContext, useState} from 'react';
import {Link} from "react-router-dom";
import styled from "styled-components";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCopyright} from '@fortawesome/free-solid-svg-icons'
import {SectionsContext} from "../App";

const sections = require('../functions/utils')

export const Navbar = () => {
    const { currentSection, setCurrentSection } = useContext(SectionsContext)
    const [isResponsiveMenuOpen, setIsResponsiveMenuOpen] = useState(false)
    const year = new Date().getFullYear()
    return (
        <>
            <NavbarBox>
                <CopyrightContainer>
                    <_FontAwesomeIcon icon={faCopyright}/>
                    <Year>{year}</Year>
                </CopyrightContainer>
                <NavLinks>
                    {sections.sections.map((section, index) => (
                        <HoverNavLink
                            key={index}
                            as={Link}
                            to={'/'.concat(section)}
                            onClick={() => setCurrentSection(section)}
                            isSelected={(currentSection === section)}
                        >
                            {section}
                        </HoverNavLink>
                    ))}
                </NavLinks>
                <HamburgerIcon onClick={() => setIsResponsiveMenuOpen(!isResponsiveMenuOpen)}>
                    <Line />
                    <Line />
                    <Line />
                </HamburgerIcon>
            </NavbarBox>
            <ResponsiveMenu isVisible={isResponsiveMenuOpen}>
                <CloseButton onClick={() => setIsResponsiveMenuOpen(!isResponsiveMenuOpen)}>Cerrar</CloseButton>
                <ResponsiveMenuElements>
                    {sections.sections.map((section, index) => (
                        <HoverNavLink key={index} as={Link} to={'/'.concat(section)} onClick={() => {sections.activePage(section); setIsResponsiveMenuOpen(!isResponsiveMenuOpen)}}>
                            {section}
                        </HoverNavLink>
                    ))}
                </ResponsiveMenuElements>
            </ResponsiveMenu>
        </>
    )
}

const CopyrightContainer = styled.div`{
  display: flex;
  flex-direction: row;
}`

const HoverNavLink = styled.a`{
  padding: 15px;
  color: ${(props) => (props.isSelected ? '#000' : '#fff')};
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;

  :hover {
    cursor: pointer;
    color: #000;
  }
}`

const Year = styled.div`{
  color: #fff;
  align-self: center;
  margin-left: 10px;
}`

const NavbarBox = styled.div`{
  width: 100%;
  height: 50px;
  background: #999999;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  @media(max-width: 950px) {
    justify-content: space-between;
  }
}`

const ResponsiveMenu = styled.div`{
  width: 100%;
  height: 100%;
  background: #999999;
  position: fixed;
  top: 0;
  z-index:1000;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
}`

const ResponsiveMenuElements = styled.div`{
  display: flex;
  flex-direction: column;
}`

const CloseButton = styled.div`{
  position: absolute;
  top: 30px;
  right: 30px;
  color: #FFFFFF;
  cursor: pointer;
}`

const NavLinks = styled.div`{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  
  @media(max-width: 950px) {
    display: none;
  }
}`

const Line = styled.div``

const HamburgerIcon = styled.div`{
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;
  margin-right: 30px;
  
  ${Line} {
    width: 20px;
    height: 2px;
    border-radius: 10px;
    background-color: #FFFFFF;
    transform-origin: 1px;
    transition: all 0.3s linear;
  }
  
  @media(min-width: 950px) {
    display: none;
  }
}`

const _FontAwesomeIcon = styled(FontAwesomeIcon)`{
  margin-left: 50px;
  color: #fff;
  align-self: center;

  @media(max-width: 950px) {
    margin-left: 20px;
  }
}`
