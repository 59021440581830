import React from 'react';
import styled from "styled-components";
import i_web from "../media/i_web.png";

export const Extract = (props) => {
    return (
        <Accordion>
            <Resume dangerouslySetInnerHTML={{__html: props.extracto}} />
            <p>{props.mail}</p>
            <FooterBox>
                <img src={'./img/' + props.foto} style={styles.image} alt="Imagen del investigadore"/>
                {props.link && <a href={props.link} target="_blank"><img src={i_web} style={styles.icon} alt="Link externo del investigadore"/></a>}
            </FooterBox>
        </Accordion>
    )
}

const Accordion = styled.div`{
  width: 100%;
  border-bottom: 1px solid #999999;
  padding-bottom: 20px;
  margin-bottom: 30px;
}`

const Resume = styled.p`{
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 18px;
  padding-right: 30px;
  
  p {
    margin: 2px 0 0 0;
  }
}`

const styles = {
    icon: {
        color: "#999999",
        marginLeft: "25px",
        alignSelf: "center",
    },
    image: {
        width: "100px",
        height: "100px",
        borderRadius: "50%"
    }
}

const FooterBox = styled.div`{
  display: flex;
  flex-direction: row;
  align-items: center;
}`
