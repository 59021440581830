import React from "react";
import SectionTitle from '../components/SectionTitle';
import styled from "styled-components";
import activities from "../data/activities.json"
import {ActivityPeriodContainer} from "../components/ActivityPeriodContainer";

export const Transferencia = () => {
    return (
        <div>
            <SectionTitle text={""}/>
            <Content>
                {
                    activities.map((activity, index) => {
                        return <Container key={index}>
                            <ActivityPeriodContainer activity={activity} />
                        </Container>
                    })
                }
            </Content>
        </div>
    );
}

const Container = styled.div`{
}`

const Content = styled.div`{
  display: flex;
  flex-direction: column;
}`
