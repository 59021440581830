import React from 'react';

const styles = {
    title:{
        fontFamily: "GothamMedium",
        fontWeight: "bold",
        color: "#231f20",
        fontSize: "24px",
        marginTop: "0px",
    }
}

function SectionTitle(props) {
    return (
        <p style={styles.title}>{props.text}</p>
    )
}

export default SectionTitle
