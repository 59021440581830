import styled from "styled-components";

export const PageNotFound = () => {
    return <Container>
        <Number>404</Number>
        <Error>Page not found</Error>
    </Container>
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Number = styled.h2`
  font-family: GothamBold, sans-serif;
  font-size: 40px;
  color: #231f20;
  margin: 0;
`

const Error = styled.h6`
  margin-top: 0;
  font-family: GothamLight, sans-serif;
`