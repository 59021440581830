import React from "react";
import SectionTitle from '../components/SectionTitle';
import subjects from '../data/subjects.json';
import styled from "styled-components";
import i_web from "../media/i_web.png";

export const Docencia = () => {
    return (
        <Container>
            <SectionTitle text=""/>
            <Subjects>
            {
                subjects.map((subject, index) => {
                    return <Subject key={index}>
                        <Title>{subject['materia']}</Title>
                        <Team dangerouslySetInnerHTML={{__html: subject['adjuntosACargo']}} />
                        {subject['tipo'] !== 'posgrado' && <Type>{subject['tipo']}</Type>}
                        <Extract dangerouslySetInnerHTML={{__html: subject['resumen']}} />
                        {subject['link'] &&
                            <a href={subject['link']} target="_blank"><img src={i_web} alt="Link externo de la materia"/></a>
                        }
                    </Subject>
                })
            }
            </Subjects>
        </Container>
    );
}

const Container = styled.div`{
  display: flex;
  flex-direction: column;
  margin-top: 0;
}`

const Subjects = styled.div`{
  display: flex;
  flex-direction: column;
  margin-top: 0;
}`

const Subject = styled.div`{
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}`

const Title = styled.h3`{
  font-family: GothamMedium, sans-serif;
  font-size: 20px;
  margin: 0;
}`

const Team = styled.p`{
  margin: 5px 0 5px;
  font-family: GothamLight, sans-serif;;
  font-size: 16px;
  
  p {
    margin: 0;
  }
}`

const Type = styled.h4`{
  font-family: GothamLight, sans-serif;;
  font-size: 16px;
  margin: 0;
}`

const Extract = styled.p`{
  margin-top: 20px;
  
  p {
    margin: 2px 0 0 0;
  }
}`
