import React from "react";
import cabecera from '../media/CIHE_Composición 1.jpg'
import cabecera_unica2 from '../media/CIHE_Composicion_sola2.jpg'
import cabecera_unica3 from '../media/CIHE_Composicion_sola3.jpg'
import cabecera_unica4 from '../media/CIHE_Composicion_sola4.jpg'
import cabecera_unica5 from '../media/CIHE_Composicion_sola5.jpg'
import cabecera_unica from '../media/CIHE_Composicion_sola1.jpg'
import SectionTitle from '../components/SectionTitle'
import styled from "styled-components";
import useWindowDimensions from "../functions/getWindowDimensions";

export const Institucional = () => {
    const { height, width } = useWindowDimensions();

    return (
        <Container>
            <SectionTitle text=""/>
                { width < 500 ?
                    <NoImage>
                    </NoImage>
                    : <Image src={cabecera} />
                }
            <Content>
                <p>El Centro de Investigación Hábitat y Energía (CIHE) desarrolla sus actividades en el marco de la Secretaría de Investigaciones, de la Facultad de Arquitectura, Diseño y Urbanismo, Universidad de Buenos Aires (FADU-UBA). En ese ámbito, aborda tareas de investigación, docencia y transferencia sobre Sustentabilidad en Arquitectura y Urbanismo, Diseño Bioambiental, Eficiencia Energética en Edificios, Infraestructura Azul y Verde, Soluciones basadas en la Naturaleza, Adaptación basada en Ecosistemas e integración de Energías Renovables en el hábitat construido, y temáticas complementarias.
                    Los recursos físicos para el funcionamiento del Centro han sido obtenidos principalmente a través de los subsidios otorgados a los proyectos de investigación que se desarrollan en el CIHE, acreditados por la Secretaría de Ciencia y Técnica de la Universidad de Buenos Aires, la Agencia Nacional de Promoción de la Investigación, el Desarrollo Tecnológico y la Innovación (Agencia I+D+i), la Unión Europea y la Organización de las Naciones Unidas, entre otros.</p>
                <p>Los conocimientos producidos en el marco del CIHE son transferidos a grado, posgrado, instituciones y organismos públicos y privados, y al ámbito profesional y científico.</p>
                <p>Desde sus inicios, el CIHE ha formado recursos humanos a través de la dirección de becarios UBACyT, ANPCyT y CONICET, tesistas de maestría y doctorado, pasantes de grado y posgrado, programas de formación docente e investigación de la UBA, y otras instituciones nacionales y del exterior.
                    Cuenta con un Programa de Asistencia Técnica a terceros, un Laboratorio de Estudios Bioambientales y una biblioteca especializada.</p>
                <p>A lo largo de su trayectoria, los objetivos y actividades del CIHE han acompañado el contexto energético-ambiental nacional e internacional. En sus inicios el esfuerzo fundamental fue puesto en la difusión del Diseño Bioambiental y el Uso Racional de Energía.  En la actualidad, la Infraestructura Azul y Verde (IAV) y la Adaptación basada en Ecosistemas (AbE) en el marco de las Soluciones basadas en la Naturaleza (SbN), la Eficiencia Energética en Edificios, la reducción del uso de combustibles fósiles para minimizar el impacto de Gases de Efecto Invernadero (GEI), son algunos de los principales ejes de investigación.</p>
                <p>Los orígenes del CIHE se remontan al año 1984 cuando John Martin Evans y Silvia de Schiller crearon la Cátedra “Diseño Bioambiental y Arquitectura Solar” en la Carrera de Arquitectura de la FADU-UBA. Esta materia, dictada ininterrumpidamente hasta el presente con modalidad taller, dio origen a la formación de docentes e investigadores/as y al dictado de cursos de posgrado con nuevos contenidos en el ámbito académico. En 1990, se consolidó el Centro de Investigación Hábitat y Energía (Res. CD Nro. 640/90), establecido en 1987 como Programa, en el marco de la Secretaría de Investigaciones en Ciencia y Técnica de la FADU-UBA. Dentro de la estructura del Centro se establecieron: 1. el Laboratorio de Estudios Bioambientales (LEB), equipado con túnel de viento, heliodón de múltiples soles, cielo artificial, instrumental de medición, equipos y programas de computación, y 2. el Programa de Asistencia Técnica a Terceros, PATT (Resol. CD 222/94). Desde 2016, la dirección del CIHE está a cargo de Daniel Kozak (Resolución (CD) 679/16).</p>
            </Content>
        </Container>
    );
}

const Container = styled.div`{
  display: flex;
  flex-direction: column;
}`

const Content = styled.div`{
  margin-top: 20px;

  p {
    margin-top: 5px;
    margin-bottom:5px;
  }
}`

const Image = styled.img`{
    flex-shrink: 1;
}`

const NoImage = styled.div`{
  margin-top: -100px; 
}`

